var site = site || {};

(function ($) {
  // @todo finalize sorting and filtering
  Drupal.behaviors.productGrid = {
    attach: function (context, settings) {
      var $grids = $('.js-product-grid', context);
      var $carouselGrids = $grids.filter('.product-grid--carousel');
      var $carousels = $('.js-product-carousel', $carouselGrids);
      var hasQuickshop = $grids.hasClass('product-grid--quickshop');
      var $wrapper = $('.product-grid-wrapper', context);
      var $products = $('.js-product-grid-item', context);

      $products.each(function () {
        var $product = $(this).hasClass('product-brief--sku-product')
          ? $(this)
          : $(this).find('.product-brief--sku-product');

        if ($product) {
          var skuBaseId = $product.attr('data-sku-base-id')
            ? $product.attr('data-sku-base-id')
            : $product.data('sku-base-id');

          $product.trigger('product.updateRoute', [skuBaseId]);
        }
      });

      // Loop through and init the carousels.
      // Carousels might contain variations, so dynamically change the settings before constructing slick
      // @setup - new brand to adjust responsive/dots settings per designs
      $carousels.each(function () {
        var $this = $(this);
        var arrowsDiv = $this.parent().find('.carousel-controls');
        var dotsDiv = $this.parent().find('.carousel-dots');
        var slides_to_show_data = $this.closest('.js-product-grid').data('carousel-grid-items-per-row');
        var slides_to_show_info_medium =
          slides_to_show_data && slides_to_show_data.carousel_medium ? slides_to_show_data.carousel_medium : 5;
        var slides_to_show_info_small =
          slides_to_show_data && slides_to_show_data.carousel_small ? slides_to_show_data.carousel_small : 2;
        var settings = {
          appendArrows: arrowsDiv,
          infinite: true,
          slidesToShow: slides_to_show_info_medium,
          slidesToScroll: slides_to_show_info_medium,
          prevArrow:
            '<span class="slick-svg-wrapper slick-svg-wrapper__prev" role="button"><svg class="slick-arrow slick-prev"><use xlink:href="#arrow--left"></use></svg></span>',
          nextArrow:
            '<span class="slick-svg-wrapper slick-svg-wrapper__next" role="button"><svg class="slick-arrow slick-next"><use xlink:href="#arrow--right"></use></svg></span>',
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                appendDots: dotsDiv,
                arrows: true,
                dots: true,
                slidesToShow: slides_to_show_info_small,
                slidesToScroll: slides_to_show_info_small
              }
            }
          ]
        };
        // slidesToShow override
        var slidesToShowCount = $(this).data('slides-to-show');

        if (slidesToShowCount) {
          settings.slidesToShow = slidesToShowCount;
        }

        var $self = $(this);

        $self.on('product.gridInit', function (event) {
          $self.slick(settings);
        });

        $self.trigger('product.gridInit');

        // On before slide change
        $(this).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
          // Remove quickshop:
          if (hasQuickshop && Drupal.behaviors.quickshop) {
            $('.js-quickshop', $wrapper).remove();
          }
        });
      });

      var collectGridProductIds = function ($gridItems) {
        var gridItems = $.makeArray($gridItems);
        var allProductIds = $.map(gridItems, function (cell) {
          return $(cell).data('product-id');
        });

        return allProductIds;
      };
      var sortGrid = function (sortFn) {
        var $allProductCells = $products;
        var allProductIds = collectGridProductIds($allProductCells);

        allProductData = [];
        _.each(allProductIds, function (prodId, i) {
          allProductData.push(prodcat.data.getProduct(prodId));
        });
        // console.log(_.pluck(allProductData, "PRICE"));
        var sortedProductData = _.sortBy(allProductData, sortFn);
        // console.log(_.pluck(sortedProductData, "PRICE"));
        var sortedCells = [];

        _.each(sortedProductData, function (prod, i) {
          if (prod) {
            sortedCells.push($('.js-product-grid-item[data-product-id=' + prod.PRODUCT_ID + ']'));
          }
        });
        var $grid = $grids;

        $grid.empty();
        _.each(sortedCells, function (cell, i) {
          $grid.append($(cell));
        });
      };

      $(document).on('mpp_sort:selected', function (e, sortFn) {
        var $productGrid = $grids;

        sortGrid(sortFn);
        site.util.grids.equalHeightsGrids($productGrid);
      });

      // Quickshop Hover State on Desktop
      $grids
        .on('mouseover', '.product__image-link, .product-brief__quickshop-launch', function () {
          var $productBrief = $(this).parents('.product-brief__container');

          // do not show quickshop if product is a kit
          if (!$productBrief.hasClass('product-brief--is-kit')) {
            $productBrief.find('.product-brief__quickshop-launch').addClass('qs-active');
          }
        })
        .on('mouseout', '.product__image-link, .product-brief__quickshop-launch', function () {
          var $productBrief = $(this).parents('.product-brief__container');

          $productBrief.find('.product-brief__quickshop-launch').removeClass('qs-active');
        });

      $grids
        .on('mouseover', '.product-brief__container', function () {
          $(this).find('.ymal-class').removeClass('hidden');
        })
        .on('mouseout', '.product-brief__container', function () {
          $(this).find('.ymal-class').addClass('hidden');
        });

      function menuToggle() {
        $('.js-product-grid-item').removeClass('hidden');
        $('.mpp .no-results, .mpp .category-text').addClass('hidden');
      }
      if ($('.js-perfume-filters').attr('data-filter-values')) {
        site.getFilters(true);
      }

      $('.js-mpp-filter-content input', context).change(function (e) {
        e.preventDefault();
        var activeMenus = [];
        var showAll = $('.js-perfume-filters')
          .data('show-all')
          .replace(/(^[,\s]+)|([\s]+)|([,\s]+$)/g, '')
          .toLowerCase();

        $('.mpp-filter-seperator').removeClass('hidden');
        $('.product-grid').trigger('grid.reflow');
        if ($(this).val() !== showAll) {
          $(this).toggleClass('active');
          $('.js-mpp-filter-content input.active').each(function () {
            activeMenus.push($(this).val());
          });
          if ($('.js-mpp-filter-content input.active').length !== 0) {
            filterMenus(activeMenus);
          } else {
            menuToggle();
          }
        } else {
          $('.js-mpp-filter-content input:checkbox').not(':first').removeClass('active').removeAttr('checked');
          menuToggle();
        }
      });

      function filterMenus(params) {
        $('.js-mpp-filter-content input:checkbox:first').removeAttr('checked');
        var $fragrance = $('.js-product-grid-item');

        $fragrance.addClass('hidden');
        var unusual_combination = [];

        $.each($fragrance, function (index, val) {
          $(val).attr(
            'data-fragrances',
            $(val)
              .attr('data-fragrances')
              .replace(/(^[,\s]+)|([\s]+)|([,\s]+$)/g, '')
              .toLowerCase()
          );
          var current = $(val).attr('data-fragrances'); // get current prod id

          if (current) {
            var FragArr = current.split(',');
          }
          var $fragranceType = $('.js-product-grid-item[data-fragrances="' + current + '"] ');
          var filterResult = params.every(function (val) {
            if (current) {
              return FragArr.indexOf(val) !== -1;
            }
          });

          if (filterResult) {
            $(this).removeClass('hidden');
            $('.product-grid').trigger('grid.reflow');
            unusual_combination.push($fragranceType.length);
          }
        });

        $('.mpp .no-results, .mpp .category-text').addClass('hidden');
        if (unusual_combination.length === 0) {
          $('.mpp .no-results, .mpp .category-text').removeClass('hidden');
          $('.mpp-filter-seperator').addClass('hidden');
        }
      }
      $(window).resize(function () {
        $('.product-grid').trigger('grid.reflow');
      });
    }
  };
})(jQuery);
